@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&display=swap');
* {
  box-sizing: border-box;
}

body {
  background-color: white;
  background-size: cover;
  margin: 0;
  padding: 0;

  font-family: 'Josefin Sans', sans-serif;
  line-height: 1.25;
}

img {
  max-width: 100%;
}

.App {
  text-align: center;
}

.hidden {
  display: none;
}

.Layout {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0px;
  height: 100vh;
  width: 100%;
}

.Layout__Left {
  margin: 15vh 0;
  flex: 1;
  background: right center /cover white no-repeat url("../public/left.jpg");
}
.Layout__Right {
  margin: 15vh 0;
  flex: 1;
  background: left center /cover white no-repeat url("../public/right.jpg");
}
.Layout__Center {
  flex: 0 1 50%;
  /* border: 5px solid #8a9a8a; */
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.5), 0 4px 22px 0 rgba(0, 0, 0, 0.22), 0 8px 15px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  height: 90vh;
  top: 5vh;
}

@media (max-width: 1100px) {

}
@media (max-width: 600px) {
  .Layout {
    display: block;
  }
  .Layout__Left {
    display: none;
  }
  .Layout__Right {
    display: none;
  }
  .Layout__Center {
    top: 0;
    height: auto;
  }
}

.Menu {
  width: 100%;
  background-color: #ddd350;
  padding: 25px 0px;
  text-align: left;
}
.Menu a {
  padding: 6px 12px;
}
.Menu__Home {
  border-right: solid white 1px;
}
@media (max-width: 1100px) {
  .Menu {
    padding: 15px 0px;
  }
  .Menu a {
    padding: 6px 18px;
  }
}

.Content {
  text-align: center;
  width: 100%;
  position: relative;
  overflow-y: auto;
  height: calc(90vh - 67px);
  padding: 0 10px;
}

@media (max-width: 600px) {
  .Content {
    height: auto;
    overflow-y: initial;
  }
}

.Vcelnice .Images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4%;
}
.Vcelnice .Images img {
  flex: 1;
}

.About {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}
.History {
  margin-top: 30px;
}
.Prodej_Medu {
  margin-top: 45px;
}

.Oddelky {
  margin-top: 45px;
  margin-bottom: 30px;
}

h1 {
  margin: 45px 0 45px;
}

h3 {
  font-weight: bold;
  color: #c9bb00;
}

/*
Včelnice  Horní Sněžná - ekologický chov včel a prodej včelího medu.
Med ze včelnice Horní Sněžné je Šumavský lesní med, získaný pouze z panenských plástů, raw a z ekologického chovu.
Nabízím i další včelí produkty jako včelí vosk a propolis, taktéž v eko kvalitě.
 */